import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19f3e4be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "research-filter_main-container" }
const _hoisted_2 = { class: "fav-container fav-genre-container" }
const _hoisted_3 = {
  key: 1,
  class: "no-fav-genre"
}
const _hoisted_4 = { class: "fav-container mix-container" }
const _hoisted_5 = { class: "filter-period" }
const _hoisted_6 = { class: "filter-genre" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenreList = _resolveComponent("GenreList")!
  const _component_PeriodList = _resolveComponent("PeriodList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('views.research.favMusicStyles')), 1),
      ($setup.favoriteGenres.length > 0)
        ? (_openBlock(), _createBlock(_component_GenreList, {
            key: 0,
            "genre-data": $setup.favoriteGenres,
            "selected-data": $props.selectedFavGenres,
            "on-select": $setup.onSelectFavGenre
          }, null, 8, ["genre-data", "selected-data", "on-select"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.profile.noFavoriteGenre')), 1)
          ]))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('views.participantDashboard.discovery.periods')), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_PeriodList, {
          "selected-data": $props.selectedPeriods,
          "on-submit": $setup.onSelectPeriod
        }, null, 8, ["selected-data", "on-submit"])
      ]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('views.participantDashboard.discovery.musicalStyles')), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_GenreList, {
          "genre-data": $setup.genres,
          "selected-data": $props.selectedGenres,
          "on-select": $setup.onSelectGenre
        }, null, 8, ["genre-data", "selected-data", "on-select"])
      ])
    ])
  ]))
}