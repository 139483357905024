import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "onClick", "draggable", "onDragstart", "onTouchmove"]
const _hoisted_2 = { class: "track-details" }
const _hoisted_3 = {
  key: 0,
  class: "logo-container"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "title-container"
}
const _hoisted_6 = {
  key: 0,
  class: "track-progress"
}
const _hoisted_7 = { class: "progress" }
const _hoisted_8 = { class: "track-timings" }
const _hoisted_9 = { class: "start-time" }
const _hoisted_10 = { class: "start-time" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "track-action-icons" }
const _hoisted_13 = {
  key: 0,
  class: "play-icon action-icon"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 5,
  class: "share-icon action-icon"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 6,
  class: "remove-icon action-icon"
}
const _hoisted_22 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "musical-list-main-container",
    style: _normalizeStyle({ height: _ctx.height })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItems, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        id: _ctx.musicalListItemIdTextPrefix + item.id?.toString(),
        onClick: ($event: any) => (_ctx.onRowClick && _ctx.onRowClick(item)),
        class: _normalizeClass(["card-item", {
                'cursor-pointer':
                    _ctx.onRowClick &&
                    (item.type === _ctx.MusicalListItemType.Track || item.type === _ctx.MusicalListItemType.Playlist),
                'card-item-three-division playing': item.progressBar,
                'card-item-two-division': !item.progressBar,
            }]),
        draggable: _ctx.draggable,
        onDragstart: ($event: any) => (_ctx.draggable && _ctx.startDrag($event, item)),
        onTouchmove: ($event: any) => (_ctx.draggable && _ctx.handleTouchMove($event, item)),
        style: _normalizeStyle({ backgroundColor: _ctx.rowColor })
      }, [
        _createElementVNode("div", _hoisted_2, [
          (item.logo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "track-icon",
                  style: _normalizeStyle({ padding: item.type === _ctx.MusicalListItemType.Playlist ? '0.3rem' : 0 })
                }, [
                  _createElementVNode("img", {
                    src: item.logo
                  }, null, 8, _hoisted_4)
                ], 4)
              ]))
            : _createCommentVNode("", true),
          (item.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", null, _toDisplayString(item.title), 1),
                _createElementVNode("span", null, _toDisplayString(item.type === _ctx.MusicalListItemType.Track ? item.artist : item.description), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (item.progressBar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", null, _toDisplayString(item.progressBar.currDuration), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", null, _toDisplayString(item.progressBar.duration), 1)
                  ])
                ]),
                _createElementVNode("div", {
                  class: "container",
                  onClick: item.progressBar.onClick
                }, [
                  _createElementVNode("div", {
                    style: _normalizeStyle({ width: item.progressBar.progressWidth }),
                    class: "skill html"
                  }, null, 4)
                ], 8, _hoisted_11)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.onPlay && item.type === _ctx.MusicalListItemType.Track)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.onPlayTrack(item)), ["stop"])
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: _ctx.playingTrackBilan?.id === item.id ? 'fa-pause' : 'fa-play'
                  }, null, 8, ["icon"])
                ], 8, _hoisted_14)
              ]))
            : _createCommentVNode("", true),
          (_ctx.onAdd && item.type === _ctx.MusicalListItemType.Track)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["plus-icon action-icon", { enable: _ctx.checkAddedToPlaylist(item) }])
              }, [
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.onAdd && _ctx.onAdd(item)), ["stop"])
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: (_ctx.checkAddedToPlaylist(item) ? 'fa-solid' : 'fa-regular') + ' fa-square-plus'
                  }, null, 8, ["icon"])
                ], 8, _hoisted_15)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.onComment && item.type !== _ctx.MusicalListItemType.Playlist)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["comment-icon action-icon", { enable: _ctx.checkSouvenirAdded(item) }])
              }, [
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.onComment && _ctx.onComment(item)), ["stop"])
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: (_ctx.checkSouvenirAdded(item) ? 'fa-solid' : 'fa-regular') + ' fa-message'
                  }, null, 8, ["icon"])
                ], 8, _hoisted_16)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.onFavorite)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass(["love-icon action-icon", { enable: _ctx.checkFavorite(item) }])
              }, [
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.onFavorite && _ctx.onFavorite(item)), ["stop"])
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: (_ctx.checkFavorite(item) ? 'fa-solid' : 'fa-regular') + ' fa-heart'
                  }, null, 8, ["icon"])
                ], 8, _hoisted_17)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.onHate)
            ? (_openBlock(), _createElementBlock("div", {
                key: 4,
                class: _normalizeClass(["warning-icon action-icon", { enable: _ctx.checkHate(item) }])
              }, [
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.onHate && _ctx.onHate(item)), ["stop"])
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-triangle-exclamation" })
                ], 8, _hoisted_18)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.onShare)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.onShare && _ctx.onShare(item)), ["stop"])
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-share-nodes" })
                ], 8, _hoisted_20)
              ]))
            : _createCommentVNode("", true),
          (_ctx.onRemove && item.type === _ctx.MusicalListItemType.Track)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.onRemove && _ctx.onRemove(item)), ["stop"])
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-regular fa-square-minus" })
                ], 8, _hoisted_22)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 46, _hoisted_1))
    }), 128))
  ], 4))
}