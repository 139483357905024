
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { ActionLabel, ActionOrigin, ConfirmType } from '@/helper/enums/ui.enum';
import { Track } from '@/helper/interface/music/track.interface';
import { Asset } from '@/helper/interface/ui/ui.interface';
import { getAssets } from '@/services/assetsService';
import {
    currentParticipant,
    currentStaff,
    currentUserType,
    isLoading,
    isPlaylistAudioPlaying,
    playlistAudio,
    playlistCurrentAudioIndex,
    recentSessionDislikes,
    recentSessionLikes,
    resetMusicalSessionData,
} from '@/services/sharedService';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isAlertOpened, openAlertPopup } from '@/services/sharedService';
import { onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import { getIconUrl } from '@/helper/utils/genericUtils';
import { useRoute, useRouter } from 'vue-router';
import { setParticipantLike, setParticipantDislike } from '@/services/participantService';
import { track } from '@vue/reactivity';
import { UserType } from '@/helper/enums/melovie.enum';
import { setStaffLike, setStaffDislike } from '@/services/staffService';
import { useI18n } from 'vue-i18n';
import { addAction } from '@/services/sessionService';
import { ActionData } from '@/helper/interface/session.interface';

/**
 * Component to play and listen a music
 *
 * @param { Track[] } data list of musics with there assets
 */
export default {
    name: 'MusicPlayerComponent',
    props: {
        data: { type: Array as PropType<Track[]>, required: true },
    },
    setup(props: any) {
        const route = useRoute();
        const { t } = useI18n();
        const router = useRouter();
        let audio = new Audio();
        const isAudioPaused = ref<boolean>(true);
        const isAudioPlayed = ref<boolean>(false);
        const track = ref<HTMLDivElement>();
        const trackWidth = ref<string>('0%');
        const audioData = ref<Track[]>([]);
        const canToggle = ref<boolean>(false);
        const currentAudio = ref<Track>({} as Track);
        const currentAudioLogo = ref<string>(getIconUrl('/musics/music-round.png'));
        const currentAudioIndex = ref<number>(0);
        const audioDuration = ref<string>('--:--');
        const currentAudioDuration = ref<string>('00:00');

        /**
         * Initialize music player data
         */
        const initializePlayer = async (skip: boolean = false) => {
            canToggle.value = false;
            currentAudio.value = audioData.value[currentAudioIndex.value] || {};
            const actionData: ActionData = {
                trk: currentAudio.value.trk_id,
                origin: route.name === 'DiscoveryMusicPlayer' ? ActionOrigin.Discovery : ActionOrigin.Library,
            };
            addAction(ActionLabel.Select, actionData);
            if (!skip) {
                audio.pause();
                audio.src = '';
                trackWidth.value = '0%';
                audio.currentTime = 0;
                if (currentAudio.value.trk_ass_id) {
                    try {
                        isLoading.value = true;
                        const audioRes = await getAssets([currentAudio.value.trk_ass_id], AssetSourceEnum.Track);
                        if (audioRes?.[0]?.data?.length) {
                            audio.src = audioRes[0].data;
                            isAudioPaused.value
                                ? audio.pause()
                                : (audio.play(), (isAudioPlayed.value = true), addAction(ActionLabel.Play, actionData));
                        } else throw new Error(t('views.playlist.invalidAudioSource'));
                    } catch (error) {
                        openAlertPopup({
                            confirmType: ConfirmType.Error,
                            message: `${error}`,
                            right: {
                                text: t('generic.back'),
                                onClick: () => {
                                    isAlertOpened.value = false;
                                },
                            },
                        });
                    } finally {
                        isLoading.value = false;
                    }
                }
            } else isAudioPlayed.value = !isAudioPaused.value;
            if (currentAudio.value.alb_ass_id) {
                getAssets([currentAudio.value.alb_ass_id], AssetSourceEnum.Album).then((assets: Asset[]) => {
                    if (assets?.length) {
                        currentAudioLogo.value = assets[0].data || getIconUrl('/musics/music-round.png');
                    } else {
                        currentAudioLogo.value = getIconUrl('/musics/music-round.png');
                    }
                });
            } else {
                currentAudioLogo.value = getIconUrl('/musics/music-round.png');
            }
        };

        /**
         * Play or Pause a music
         */
        const handlePlayPause = () => {
            const actionData: ActionData = {
                trk: currentAudio.value.trk_id,
                origin: route.name === 'DiscoveryMusicPlayer' ? ActionOrigin.Discovery : ActionOrigin.Library,
            };
            if (audio.src?.length && audio.paused) {
                audio.play();
                isAudioPaused.value = false;
                if (isAudioPlayed.value) {
                    addAction(ActionLabel.Resume, actionData);
                } else {
                    isAudioPlayed.value = true;
                    addAction(ActionLabel.Play, actionData);
                }
            } else {
                audio.pause();
                isAudioPaused.value = true;
                addAction(ActionLabel.Pause, actionData);
            }
        };
        /**
         * Check for final track
         *
         * @return { boolean } true or false
         */
        const isLastTrack = () => currentAudioIndex.value === audioData.value.length - 1;

        /**
         * Go to next music
         *
         * @param { number } index 1 for next, -1 for previous
         * @return { boolean } true or false
         */
        const onToggleAudio = (index: number) => {
            if (isLastTrack()) onClose();
            else if (
                (index === 1 && currentAudioIndex.value < audioData.value.length - 1) ||
                (index === -1 && currentAudioIndex.value > 0)
            ) {
                const actionData: ActionData = {
                    trk: currentAudio.value.trk_id,
                    origin: route.name === 'DiscoveryMusicPlayer' ? ActionOrigin.Discovery : ActionOrigin.Library,
                };
                addAction(ActionLabel.EndPlay, actionData);
                currentAudioIndex.value += index;
                initializePlayer();
            }
        };

        /**
         * Update track progress bar
         *
         * @param { number } trackPosition position of track in window
         */
        const updateTrack = (trackPosition: number) => {
            const progress = track.value ?? new HTMLDivElement();
            const maxDuration = audio.duration;
            const position = trackPosition - progress.offsetLeft;
            let percentage = (100 * position) / progress.offsetWidth;
            percentage > 100 ? (percentage = 100) : percentage < 0 ? (percentage = 0) : null;
            trackWidth.value = percentage + '%';
            audio.currentTime = (maxDuration * percentage) / 100;
            handlePlayPause();
        };

        /**
         * Call when clicked on track
         *
         * @param { PointerEvent } event mouse pointer event to know track position
         */
        const onTrackClick = (event: PointerEvent | any) => {
            if (audio.duration) {
                audio.pause();
                updateTrack(event.pageX);
            }
        };

        /**
         * Generate music durations
         */
        const generateTime = () => {
            let width = (100 / audio.duration) * audio.currentTime;
            trackWidth.value = width + '%';
            if (audio.duration === Infinity) {
                audioDuration.value = '--:--';
                return;
            }
            let durationMinute = Math.floor(audio.duration / 60);
            let durationSecond = Math.floor(audio.duration - durationMinute * 60);
            let currentMinute = Math.floor(audio.currentTime / 60);
            let currentSecond = Math.floor(audio.currentTime - currentMinute * 60);
            audioDuration.value =
                (!audio.duration ? '--' : durationMinute < 10 ? '0' + durationMinute : durationMinute) +
                ':' +
                (!audio.duration ? '--' : durationSecond < 10 ? '0' + durationSecond : durationSecond);
            currentAudioDuration.value =
                (currentMinute < 10 ? '0' + currentMinute : currentMinute) +
                ':' +
                (currentSecond < 10 ? '0' + currentSecond : currentSecond);
        };

        const handleLikeDislike = (like: boolean = true) => {
            if (like) {
                if (recentSessionDislikes.value.includes(currentAudio.value))
                    recentSessionDislikes.value.splice(recentSessionDislikes.value.indexOf(currentAudio.value), 1);

                if (!recentSessionLikes.value.includes(currentAudio.value))
                    recentSessionLikes.value.push(currentAudio.value);
            } else {
                if (recentSessionLikes.value.includes(currentAudio.value))
                    recentSessionLikes.value.splice(recentSessionLikes.value.indexOf(currentAudio.value), 1);

                if (!recentSessionDislikes.value.includes(currentAudio.value))
                    recentSessionDislikes.value.push(currentAudio.value);
            }
            canToggle.value = true;
        };

        const onClose = () => {
            const actionData: ActionData = {
                trk: currentAudio.value.trk_id,
                origin: route.name === 'DiscoveryMusicPlayer' ? ActionOrigin.Discovery : ActionOrigin.Library,
            };
            addAction(ActionLabel.EndPlay, actionData);
            route.name === 'DiscoveryMusicPlayer' &&
            (recentSessionLikes.value.length || recentSessionDislikes.value.length)
                ? router.push('/participant/music-report')
                : router.back();
        };

        if (route.name === 'PlaylistMusicPlayer') {
            watch(
                () => currentAudioIndex.value,
                () => (playlistCurrentAudioIndex.value = currentAudioIndex.value)
            );
            watch(
                () => isAudioPaused.value,
                () => (isPlaylistAudioPlaying.value = !isAudioPaused.value)
            );
        }

        const saveLikeAndDislike = async () => {
            try {
                isLoading.value = true;
                if (recentSessionLikes.value.length) {
                    await Promise.all(
                        recentSessionLikes.value.map(async (track: Track) => {
                            switch (currentUserType.value) {
                                case UserType.Participant:
                                    return await setParticipantLike(currentParticipant.value?.par_id!, track.trk_id);

                                case UserType.Staff:
                                    return await setStaffLike(currentStaff.value?.staf_id!, track.trk_id);
                            }
                        })
                    );
                }
                if (recentSessionDislikes.value.length) {
                    await Promise.all(
                        recentSessionDislikes.value.map(async (track: Track) => {
                            switch (currentUserType.value) {
                                case UserType.Participant:
                                    return await setParticipantDislike(currentParticipant.value?.par_id!, track.trk_id);

                                case UserType.Staff:
                                    return await setStaffDislike(currentStaff.value?.staf_id!, track.trk_id);
                            }
                        })
                    );
                }
            } catch (error) {
                isLoading.value = false;
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: `${error}`,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
            } finally {
                isLoading.value = false;
            }
        };

        onMounted(() => {
            if (route.name === 'PlaylistMusicPlayer') {
                currentAudioIndex.value = playlistCurrentAudioIndex.value;
                audio = playlistAudio;
                isAudioPaused.value = !isPlaylistAudioPlaying.value;
            }
            audioData.value = props.data || [];
            initializePlayer(route.name === 'PlaylistMusicPlayer' && audio.currentTime > 0);
            if (route.name === 'PlaylistMusicPlayer') generateTime();
            audio.ontimeupdate = () => generateTime();
            audio.ondurationchange = () => generateTime();
            audio.onended = () => {
                if (canToggle.value) onToggleAudio(1);
                else isAudioPaused.value = true;
            };
        });

        onUnmounted(() => {
            if (route.name === 'PlaylistParticipant') {
                saveLikeAndDislike().then((value: void) => resetMusicalSessionData(true));
                return;
            }
            audio.pause();
            if (route.name !== 'ParticipantMusicReport')
                saveLikeAndDislike().then((value: void) => resetMusicalSessionData());
        });

        return {
            track,
            audioData,
            canToggle,
            trackWidth,
            currentAudio,
            isAudioPaused,
            audioDuration,
            currentAudioLogo,
            currentAudioIndex,
            recentSessionLikes,
            currentAudioDuration,
            recentSessionDislikes,
            onClose,
            isLastTrack,
            onTrackClick,
            onToggleAudio,
            handlePlayPause,
            handleLikeDislike,
        };
    },
};
