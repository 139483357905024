import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2611b066"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "period-icon-title" }
const _hoisted_3 = { class: "img-container" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periodData, (period, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass(_ctx.isPeriodSelected(period) ? 'contents-selected' : 'contents'),
      onClick: ($event: any) => (_ctx.onSubmit && _ctx.onSubmit(period))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(period.muspe_label), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: period.icon,
          class: _normalizeClass(_ctx.isPeriodSelected(period) ? 'icon-image' : 'hide-image')
        }, null, 10, _hoisted_4)
      ])
    ], 10, _hoisted_1))
  }), 128))
}