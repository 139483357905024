import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d7e37c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-container" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "item-container" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "image-container" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "artist" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString($props.title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tracks, (track) => {
        return (_openBlock(), _createElementBlock("div", {
          key: track.id,
          class: "item"
        }, [
          ($props.closeBtn)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "close-button",
                onClick: ($event: any) => ($props.closeBtn(track))
              }, "X", 8, _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: track.logo || require('@/assets/musics/music-round.png'),
              alt: track.title
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(track.title), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(track.artist), 1)
        ]))
      }), 128))
    ])
  ]))
}