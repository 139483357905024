import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26edbeb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "favorite-card_main-container" }
const _hoisted_2 = { class: "favorite-card_header" }
const _hoisted_3 = {
  key: 0,
  class: "edit-container"
}
const _hoisted_4 = { class: "favorite-card_body" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "text-container"
}
const _hoisted_7 = {
  key: 2,
  class: "text-container"
}
const _hoisted_8 = {
  key: 0,
  class: "count"
}
const _hoisted_9 = {
  key: 1,
  class: "no-favorite"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString($props.title), 1),
      ($props.onEdit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "icon-container",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.onEdit && $props.onEdit()))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-pen" })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "card-item"
        }, [
          (item.logo)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "logo-container",
                style: _normalizeStyle({ width: $props.logoSize, height: $props.logoSize })
              }, [
                _createElementVNode("img", {
                  src: item.logo
                }, null, 8, _hoisted_5)
              ], 4))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(item.text), 1)
              ])),
          (!$props.itemsWithText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(item.text), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      ($setup.getRestItemCount() > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "+" + _toDisplayString($setup.getRestItemCount()), 1))
        : _createCommentVNode("", true),
      (!$props.items || !$props.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("span", null, _toDisplayString($props.noItemText), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}