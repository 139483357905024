import { Participant } from '@/helper/interface/participant.interface';
import { BubbleListData } from '@/helper/interface/ui/components/bubbleList';
import { FilterData } from '@/helper/interface/ui/components/filter';
import { Staff } from '@/helper/interface/staff.interface';

/**
 * Filter connection page data.
 *
 * @param {FilterData} filterData filter form-data of filter component.
 * @param {Staff[]} users list of users.
 * @return {BubbleListData[]} list of filtered user names.
 */
export const filterConnectionData = (filterData: FilterData, users: Staff[]) => {
    return users
        .filter((user: Staff) => {
            let check: boolean = true;
            if (check && filterData.name.length) {
                const regex = new RegExp(filterData.name, 'gi');
                check = regex.test(user.staf_firstname);
            }
            if (check && filterData.alphabets.length)
                check = filterData.alphabets.some((alphabet: string) => user.staf_firstname.charAt(0) === alphabet);

            if (check && filterData.locations.length)
                check = filterData.locations.some((location: number) => user.services?.includes(location));

            return check;
        })
        .map((user: Staff) => {
            return { id: user.staf_id, value: `${user.staf_firstname} ${user.staf_lastname}` } as BubbleListData;
        });
};
/**
 * Filter session page data.
 *
 * @param {FilterData} filterData filter form-data of filter component.
 * @param {Staff[]} users list of users.
 * @return {BubbleListData[]} list of filtered user names.
 */
export const filterSessionData = (filterData: FilterData, users: Participant[]) => {
    return users
        .filter((user: Participant) => {
            let check: boolean = true;
            if (check && filterData.name.length) {
                const regex = new RegExp(filterData.name, 'gi');
                check = regex.test(user.par_firstname);
            }
            if (check && filterData.alphabets.length)
                check = filterData.alphabets.some((alphabet: string) => user.par_firstname.charAt(0) === alphabet);

            if (check && filterData.locations.length)
                check = filterData.locations.some((location: number) => user.services?.includes(location));
            if (check && filterData.groups.length)
                check = filterData.groups.some((group: number) => user.groups?.includes(group));

            return check;
        })
        .map((user: Participant) => {
            return { id: user.par_id, value: `${user.par_firstname} ${user.par_lastname}` } as BubbleListData;
        });
};
