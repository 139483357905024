
import { QuestionnaireType } from '@/helper/enums/melovie.enum';
import { GenreWithAsset } from '@/helper/interface/music/genre.interface';
import { allGenresWithAssets } from '@/services/sharedService';
import { defineComponent, PropType, ref } from 'vue';

/**
 * Activity card component containing text and numbers of current page.
 * @param {array} selectedData an array to show the selected genre icons
 * @param {Function} onSelect  function to execute codes when an user selects genres
 */
export default defineComponent({
    name: 'QuestionnaireGenreContainer',
    props: {
        genreData: { type: Array as PropType<GenreWithAsset[]> },
        selectedData: Array as PropType<GenreWithAsset[]>,
        onSelect: Function as PropType<(genre: GenreWithAsset) => void>,
    },
    setup(props: any) {
        const genres = ref<GenreWithAsset[]>(props.genreData ?? allGenresWithAssets.value);
        /**
         * Checking if the genre received as props is actually in db or not
         */
        const isGenreSelected = (genre: GenreWithAsset): boolean =>
            props.selectedData?.find((checkGenre: GenreWithAsset) => checkGenre.gmus_id === genre.gmus_id)
                ? true
                : false;
        return {
            genres,
            isGenreSelected,
            QuestionnaireType,
        };
    },
});
