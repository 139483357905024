
import GenreList from '@/components/genre_list/GenreList.vue';
import PeriodList from '@/components/period_list/PeriodList.vue';
import { UserType } from '@/helper/enums/melovie.enum';
import { GenreWithAsset } from '@/helper/interface/music/genre.interface';
import { PeriodWithAsset } from '@/helper/interface/music/period.interface';
import { ProfileStaff } from '@/helper/interface/staff.interface';
import { getAllGenresWithAssets } from '@/services/genericService';
import { allGenresWithAssets, currentUserType, participantProfile, staffProfile } from '@/services/sharedService';
import { PropType, onBeforeMount, ref } from 'vue';
/**
 * Bubble list contain list of string as bubble view.
 *
 * @param {string[]} dataList list of string type data.
 */
export default {
    name: 'ResearchFilterComponent',
    components: {
        PeriodList,
        GenreList,
    },
    props: {
        selectedFavGenres: { type: Array as PropType<GenreWithAsset[]>, default: [] },
        selectedGenres: { type: Array as PropType<GenreWithAsset[]>, default: [] },
        selectedPeriods: { type: Array as PropType<PeriodWithAsset[]>, default: [] },
    },
    setup(props: any) {
        const favoriteGenres = ref<GenreWithAsset[]>([]);
        const genres = ref<GenreWithAsset[]>([]);

        /**
         * handles user selected periods
         *
         * @param {PeriodWithAsset} period
         */
        const onSelectPeriod = (period: PeriodWithAsset) => {
            const selectedPeriod = (props.selectedPeriods as PeriodWithAsset[]).find(
                (checkPeriod: PeriodWithAsset) => checkPeriod.muspe_id === period.muspe_id
            );
            if (selectedPeriod) {
                // if already selected then remove
                const index = (props.selectedPeriods as PeriodWithAsset[]).findIndex(
                    (checkPeriod: PeriodWithAsset) => checkPeriod.muspe_id === period.muspe_id
                );
                if (index != -1) (props.selectedPeriods as PeriodWithAsset[]).splice(index, 1);
            } else (props.selectedPeriods as PeriodWithAsset[]).push(period); // else add
        };

        /**
         * handles user selected favorite genres
         *
         * @param {GenreWithAsset} genre
         */
        const onSelectFavGenre = (genre: GenreWithAsset) => {
            const selectedFavGenre = (props.selectedFavGenres as GenreWithAsset[]).find(
                (checkGenre: GenreWithAsset) => checkGenre.gmus_id === genre.gmus_id
            );
            if (selectedFavGenre) {
                // if already selected then remove
                const index = (props.selectedFavGenres as GenreWithAsset[]).findIndex(
                    (checkGenre: GenreWithAsset) => checkGenre.gmus_id === genre.gmus_id
                );
                if (index != -1) (props.selectedFavGenres as GenreWithAsset[]).splice(index, 1);
            } else (props.selectedFavGenres as GenreWithAsset[]).push(genre); // else add
        };

        /**
         * handles user selected genres
         *
         * @param {GenreWithAsset} genre
         */
        const onSelectGenre = (genre: GenreWithAsset) => {
            const selectedGenre = (props.selectedGenres as GenreWithAsset[]).find(
                (checkGenre: GenreWithAsset) => checkGenre.gmus_id === genre.gmus_id
            );
            if (selectedGenre) {
                // if already selected then remove
                const index = (props.selectedGenres as GenreWithAsset[]).findIndex(
                    (checkGenre: GenreWithAsset) => checkGenre.gmus_id === genre.gmus_id
                );
                if (index != -1) (props.selectedGenres as GenreWithAsset[]).splice(index, 1);
            } else (props.selectedGenres as GenreWithAsset[]).push(genre); // else add
        };

        onBeforeMount(async () => {
            if (!allGenresWithAssets.value?.length) allGenresWithAssets.value = await getAllGenresWithAssets();
            const userProfile: ProfileStaff =
                currentUserType.value === UserType.Staff
                    ? staffProfile.value || {}
                    : currentUserType.value === UserType.Participant
                    ? participantProfile.value || {}
                    : ({} as ProfileStaff);
            allGenresWithAssets.value.forEach((genre: GenreWithAsset) => {
                if (userProfile.genres?.includes(genre.gmus_id)) favoriteGenres.value.push(genre);
                else genres.value.push(genre);
            });
        });
        return { favoriteGenres, genres, onSelectPeriod, onSelectFavGenre, onSelectGenre };
    },
};
