
import { QuestionnaireType } from '@/helper/enums/melovie.enum';
import { QuestionnaireData } from '@/helper/interface/eval.interface';
import { GenreWithAsset } from '@/helper/interface/music/genre.interface';
import { PeriodWithAsset } from '@/helper/interface/music/period.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { defineComponent, PropType } from 'vue';

/**
 * Questionnaire component final
 *
 * @param {array} data contains the questions and answers
 * @param {Function} onEdit triggers a function to detect on question changed by the user
 */
export default defineComponent({
    name: 'QuestionnaireFinalComponent',
    props: {
        data: { type: Array as PropType<QuestionnaireData[]>, required: true },
        onEdit: Function,
    },
    setup() {
        const getAnswerText = (questionnaireData: QuestionnaireData) => {
            let answerText = '';
            const questionMetaData = questionnaireData.children?.[0];
            if (questionMetaData?.answer) {
                switch (questionMetaData.eval_type) {
                    case QuestionnaireType.Period:
                        answerText = (questionMetaData.answer as PeriodWithAsset[])
                            .map((period: PeriodWithAsset) => period.muspe_label)
                            .toString();
                        break;
                    case QuestionnaireType.Genre:
                        answerText = (questionMetaData.answer as GenreWithAsset[])
                            .map((genre: GenreWithAsset) => genre.gmus_label)
                            .toString();
                        break;
                    case QuestionnaireType.Artist:
                        answerText = (questionMetaData.answer as MusicalListItem[])
                            .map((artist: MusicalListItem) => artist.title)
                            .toString();
                        break;
                    case QuestionnaireType.Album:
                        answerText = (questionMetaData.answer as MusicalListItem[])
                            .map((album: MusicalListItem) => album.title)
                            .toString();
                        break;
                    case QuestionnaireType.Track:
                        answerText = (questionMetaData.answer as MusicalListItem[])
                            .map((track: MusicalListItem) => track.title)
                            .toString();
                        break;
                    default:
                        answerText = questionMetaData.answer;
                        break;
                }
            }
            return answerText;
        };
        return {
            QuestionnaireType,
            getAnswerText,
        };
    },
});
