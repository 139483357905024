
import { FavoriteCardItem } from '@/helper/interface/ui/components/favoriteCard';
import { PropType, ref } from 'vue';
import QuestionnaireModal from '@/components/questionnaire_modal/QuestionnaireComponent.vue';
import { QuestionnaireType, UserType } from '@/helper/enums/melovie.enum';
import {
    currentUserType,
    participantFavorites,
    participantProfile,
    staffFavorites,
    staffProfile,
} from '@/services/sharedService';
import { ProfileParticipant } from '@/helper/interface/participant.interface';
import { ProfileStaff } from '@/helper/interface/staff.interface';
import { FavoritesStaff, FavoritesPart } from '@/helper/interface/favorite.interface';

/**
 * Cards to show user favorites in profile page
 *
 * @param { string } title title to show on card header.
 * @param { FavoriteCardItem[] } items card items to show in cards.
 * @param { string } logoSize card logo width.
 * @param { boolean } itemsWithText it is used to check if the item having text or not
 * @param { Function } onEdit is a function to trigger edit popup
 */
export default {
    name: 'FavoriteCard',
    components: {
        QuestionnaireModal,
    },
    props: {
        title: { type: String, required: true },
        items: { type: Array as PropType<FavoriteCardItem[]>, required: true },
        noItemText: { type: String, required: true },
        itemsWithText: { type: Boolean, default: false },
        itemType: String as PropType<QuestionnaireType>,
        logoSize: String,
        onEdit: Function,
    },
    setup(props: any) {
        const getRestItemCount = (): number => {
            if (props.itemType) {
                const userProfile = ref<ProfileStaff | ProfileParticipant>({});
                const userFavorite = ref<FavoritesStaff | FavoritesPart>({});
                if (props.itemType === QuestionnaireType.Genre || props.itemType === QuestionnaireType.Period) {
                    userProfile.value =
                        currentUserType.value === UserType.Staff
                            ? staffProfile.value || {}
                            : currentUserType.value === UserType.Participant
                            ? participantProfile.value || {}
                            : ({} as ProfileStaff);
                } else if (props.itemType === QuestionnaireType.Artist || props.itemType === QuestionnaireType.Track) {
                    userFavorite.value =
                        currentUserType.value === UserType.Staff
                            ? staffFavorites.value || {}
                            : currentUserType.value === UserType.Participant
                            ? participantFavorites.value || {}
                            : ({} as FavoritesStaff);
                }
                switch (props.itemType) {
                    case QuestionnaireType.Genre:
                        return (userProfile.value.genres?.length || 0) - (props.items?.length || 0);
                    case QuestionnaireType.Period:
                        return (userProfile.value.periods?.length || 0) - (props.items?.length || 0);
                    case QuestionnaireType.Artist:
                        return (userFavorite.value.artists?.length || 0) - (props.items?.length || 0);
                    case QuestionnaireType.Track:
                        return (userFavorite.value.tracks?.length || 0) - (props.items?.length || 0);
                }
            }
            return 0;
        };
        return { getRestItemCount };
    },
};
