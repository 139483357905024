import { getAllEval } from '@/services/questionnaireService';
import { getAllGenresApi } from './../api/music/genreApi';
import { getGroupsApi, getTroublesApi } from '@/api/listApi';
import { Group } from '@/helper/interface/group.interface';
import { Genre, GenreWithAsset } from '@/helper/interface/music/genre.interface';
import { Period, PeriodWithAsset } from '@/helper/interface/music/period.interface';
import { getAllPeriodsApi } from '@/api/music/periodApi';
import { Asset } from '@/helper/interface/ui/ui.interface';
import { getAssets } from './assetsService';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { getIconUrl } from '@/helper/utils/genericUtils';
import {
    allEval,
    allGenresWithAssets,
    allPeriodsWithAssets,
    currentParticipant,
    currentStaff,
    participantFavorites,
    participantHates,
    participantProfile,
    staffFavorites,
    staffHates,
    staffProfile,
} from './sharedService';
import { UserType } from '@/helper/enums/melovie.enum';
import { getStaffFavoritesAndHates, getStaffProfile } from './staffService';
import { getParticipantFavoritesAndHates, getParticipantProfile } from './participantService';
import { FavoritesUser } from '@/helper/interface/favorite.interface';
import { Trouble } from '@/helper/interface/medicalReport.interface';

/**
 * Fetch all groups from the API
 *
 * @returns {Promise<Group[]>} list of groups.
 */
export const getAllGroups = () => {
    return new Promise<Group[]>((resolve, reject) => {
        getGroupsApi()
            .then((data: Group[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
/**
 * Fetch all genres with assets from the API
 *
 * @returns {Promise<GenreWithAsset[]>} list of genres.
 */
export const getAllGenresWithAssets = () => {
    return new Promise<GenreWithAsset[]>((resolve, reject) => {
        getAllGenresApi({ lang: 'fr-FR' })
            .then(async (data: Genre[]) => {
                let assets: Asset[] = await getAssets(
                    data.filter((genre: Genre) => genre.gmus_ass_id).map((genre: Genre) => genre.gmus_ass_id!),
                    AssetSourceEnum.Reference
                );
                resolve(
                    data.filter((genre: Genre) => { return genre.used}).map((genre: Genre) => {
                        let result: GenreWithAsset = genre;
                        result.icon = assets.find((asset: Asset) => asset.id === genre.gmus_ass_id)?.data;
                        return result;
                    })
                );
            })
            .catch((error) => {
                reject(error);
            });
    });
};
/**
 * Fetch all periods with assets from the API
 *
 * @returns {Promise<PeriodWithAsset[]>} list of periods.
 */
export const getAllPeriodsWithAssets = () => {
    return new Promise<PeriodWithAsset[]>((resolve, reject) => {
        getAllPeriodsApi({ lang: 'fr-FR' })
            .then(async (data: Period[]) => {
                let assets: Asset[] = await getAssets(
                    data.filter((period: Period) => period.muspe_ass_id).map((period: Period) => period.muspe_ass_id!),
                    AssetSourceEnum.Reference
                );
                resolve(
                    data
                        .map((period: Period) => {
                            let result: PeriodWithAsset = period;
                            result.icon =
                                assets.find((asset: Asset) => asset.id === period.muspe_ass_id)?.data ||
                                getIconUrl('/musics/music-round.png');
                            return result;
                        })
                        .sort((current: PeriodWithAsset, next: PeriodWithAsset) =>
                            current.muspe_end_year && next.muspe_end_year
                                ? current.muspe_end_year - next.muspe_end_year
                                : 1
                        )
                );
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const loadGenericData = () => {
    return new Promise<void>(async (resolve, resect) => {
        try {
            if (!allEval.value?.length) allEval.value = await getAllEval();
            if (!allGenresWithAssets.value?.length) allGenresWithAssets.value = await getAllGenresWithAssets();
            if (!allPeriodsWithAssets.value?.length) allPeriodsWithAssets.value = await getAllPeriodsWithAssets();
            resolve();
        } catch (error) {
            resect(error);
        }
    });
};

export const loadUserProfileData = (userType: UserType = UserType.Staff, forceLoad: boolean = false) => {
    return new Promise<void>(async (resolve, resect) => {
        try {
            if (userType === UserType.Staff) {
                if ((!staffProfile.value?.genres?.length && !staffProfile.value?.periods?.length) || forceLoad)
                    staffProfile.value = await getStaffProfile(currentStaff.value?.staf_id!);
            }
            if (userType === UserType.Participant) {
                if (
                    (!participantProfile.value?.genres?.length && !participantProfile.value?.periods?.length) ||
                    forceLoad
                )
                    participantProfile.value = await getParticipantProfile(currentParticipant.value?.par_id!);
            }
            resolve();
        } catch (error) {
            resect(error);
        }
    });
};

export const loadUserFavoriteData = (userType: UserType = UserType.Staff, forceLoad: boolean = false) => {
    return new Promise<void>(async (resolve, resect) => {
        try {
            if (userType === UserType.Staff) {
                if ((!staffFavorites.value?.artists?.length && !staffFavorites.value?.tracks?.length) || forceLoad) {
                    staffFavorites.value = await getStaffFavoritesAndHates(currentStaff.value?.staf_id!);
                    staffHates.value = await getStaffFavoritesAndHates(currentStaff.value?.staf_id!, false, true);
                }
            }
            if (userType === UserType.Participant) {
                if (
                    (!participantFavorites.value?.artists?.length && !participantFavorites.value?.tracks?.length) ||
                    forceLoad
                ) {
                    participantFavorites.value = await getParticipantFavoritesAndHates(
                        currentParticipant.value?.par_id!
                    );
                    participantHates.value = await getParticipantFavoritesAndHates(
                        currentParticipant.value?.par_id!,
                        false,
                        true
                    );
                }
            }
            resolve();
        } catch (error) {
            resect(error);
        }
    });
};

export const sortUserFavorite = (userFavorite: FavoritesUser): FavoritesUser => {
    if (userFavorite.albums?.length)
        userFavorite.albums.sort((cur, next) => {
            return new Date(next.date).getDate() - new Date(cur.date).getDate();
        });
    if (userFavorite.artists?.length)
        userFavorite.artists.sort((cur, next) => {
            return new Date(next.date).getDate() - new Date(cur.date).getDate();
        });
    if (userFavorite.tracks?.length)
        userFavorite.tracks.sort((cur, next) => {
            return new Date(next.date).getDate() - new Date(cur.date).getDate();
        });

    return userFavorite;
};

/**
 * Get list of troubles from the API
 *
 * @returns {Trouble[]} list of troubles.
 */
export const getTroubles = () => {
    return new Promise<Trouble[]>((resolve, reject) => {
        getTroublesApi()
            .then((data: Trouble[]) => {
                resolve(
                    data.map((trouble: Trouble) => {
                        trouble.trouble_name = trouble.trouble_name.split(' ')[0];
                        return trouble;
                    })
                );
            })
            .catch((error) => {
                reject(error);
            });
    });
};
