import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56779686"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question-body-container-asset" }
const _hoisted_2 = { class: "choose-container" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "search-container" }
const _hoisted_5 = { class: "icon-container" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "musical-items" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "card-img" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "card-body" }
const _hoisted_12 = {
  key: 0,
  class: "no-content"
}
const _hoisted_13 = { class: "pagination-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalSidebarComponent = _resolveComponent("ModalSidebarComponent")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalSidebarComponent, {
      title: _ctx.title,
      tracks: _ctx.selectedItems,
      closeBtn: _ctx.handleDeselectItem
    }, null, 8, ["title", "tracks", "closeBtn"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.listTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_font_awesome_icon, {
            class: "search-icon",
            icon: "fa-magnifying-glass"
          })
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSearch && _ctx.handleSearch(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchText) = $event)),
          placeholder: _ctx.searchPlaceholder
        }, null, 40, _hoisted_6), [
          [_vModelText, _ctx.searchText]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredData, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "card-container",
            key: item.id,
            onClick: ($event: any) => (_ctx.handleSelectedItem(item))
          }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("img", {
                class: _normalizeClass({ 'artist-img': item.type == _ctx.MusicalListItemType.Artist }),
                src: item.logo
              }, null, 10, _hoisted_10)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", null, _toDisplayString(item.title), 1)
            ])
          ], 8, _hoisted_8))
        }), 128)),
        (_ctx.filteredData.length < 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("span", null, _toDisplayString(_ctx.noItemFound), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_Paginator, {
          totalItems: _ctx.searchedData,
          currentItems: _ctx.filteredData
        }, null, 8, ["totalItems", "currentItems"])
      ])
    ])
  ]))
}