import { ConfirmType, MusicalListItemType } from './../helper/enums/ui.enum';
import { Participant, ProfileParticipant } from '@/helper/interface/participant.interface';
import { ProfileStaff, Staff } from '@/helper/interface/staff.interface';
import { ref } from 'vue';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import { FavoritesPart, FavoritesStaff } from '@/helper/interface/favorite.interface';
import { Track } from '@/helper/interface/music/track.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { PlayListParticipant, PlayListStaff, Playlist } from '@/helper/interface/playlist.interface';
import { Artist } from '@/helper/interface/music/artist.interface';
import { Album } from '@/helper/interface/music/album.interface';
import { GenreWithAsset } from '@/helper/interface/music/genre.interface';
import { PeriodWithAsset } from '@/helper/interface/music/period.interface';
import { Eval } from '@/helper/interface/eval.interface';
import { UserType } from '@/helper/enums/melovie.enum';
import { SessionAction } from '@/helper/interface/session.interface';
import { Souvenir } from '@/helper/interface/souvenir.interface';
import { AnimationPlace } from '@/helper/interface/animationPlace.interface';

// Generic
export const isAuthenticated = ref<boolean>(false);
export const currentUserType = ref<UserType>(UserType.Staff);
export const isLoading = ref<boolean>(false);
export const allEval = ref<Eval[]>([]);
export const resetGenericData = () => {
    isAuthenticated.value = false;
    currentUserType.value = UserType.Staff;
    allEval.value = [];
};

// Confirm
export const isAlertOpened = ref<boolean>(false);
export const alertData = ref<ConfirmProps>({ confirmType: ConfirmType.Alert });
export const openAlertPopup = (confirmData: ConfirmProps) => {
    alertData.value = confirmData;
    isAlertOpened.value = true;
};

// Playlist creator modal
export const isPlaylistCreatorOpened = ref<boolean>(false);
export const tracksToAddInPlaylist = ref<MusicalListItem[]>([]);
export const openPlaylistCreator = (tracks: MusicalListItem[]) => {
    tracksToAddInPlaylist.value = tracks;
    isPlaylistCreatorOpened.value = true;
};

// Souvenir creator modal
export const isSouvenirCreatorOpened = ref<boolean>(false);
export const itemToAddSouvenir = ref<MusicalListItem>();
export const souvenirToEdit = ref<Souvenir>();

/**
 * Preparing souvenir update/create popup
 *
 * @param {MusicalListItem} item souvenir track
 * @param {Souvenir} memory souvenir current memory(update)
 */
export const openSouvenirCreator = (item: MusicalListItem, memory?: Souvenir) => {
    souvenirToEdit.value = undefined; // Reset previously edited souvenir
    let foundSouvenir;
    if (memory) foundSouvenir = memory;
    else {
        // Looking for current souvenir for update if available
        foundSouvenir = participantMemories.value.find((souvenir: Souvenir) => {
            if (souvenir.track?.id) {
                return souvenir.track.id === item.id;
            }
            if (souvenir.artist?.id) {
                return souvenir.artist.id === item.id;
            }
            if (souvenir.album?.id) {
                return souvenir.album.id === item.id;
            }
            return false;
        });
    }
    if (foundSouvenir) souvenirToEdit.value = foundSouvenir;
    itemToAddSouvenir.value = item;
    isSouvenirCreatorOpened.value = true;
};

// Staff
export const currentStaff = ref<Staff>();
export const staffFirstConnectionChecked = ref<boolean>(false);
export const newStaffQuestionnaireCount = ref<number>();
export const staffProfile = ref<ProfileStaff>();
export const staffFavorites = ref<FavoritesStaff>();
export const staffHates = ref<FavoritesStaff>();
export const staffPlaylists = ref<PlayListStaff[]>([]);
export const resetStaffData = () => {
    currentStaff.value = undefined;
    staffFirstConnectionChecked.value = false;
    newStaffQuestionnaireCount.value = undefined;
    staffProfile.value = undefined;
    staffFavorites.value = undefined;
    staffPlaylists.value = [];
};

// Participant
export const currentParticipant = ref<Participant>();
export const participantFirstConnectionChecked = ref<boolean>(false);
export const newParticipantQuestionnaireCount = ref<number>();
export const participantProfile = ref<ProfileParticipant>();
export const participantFavorites = ref<FavoritesPart>();
export const participantHates = ref<FavoritesPart>();
export const participantPlaylists = ref<PlayListParticipant[]>([]);
export const participantMemories = ref<Souvenir[]>([]);
export const isSouvenirOpened = ref<boolean>(false);
export const souvenirSelectedPageNumber = ref<number>(1);
export const resetParticipantData = () => {
    currentUserType.value = UserType.Staff;
    currentParticipant.value = undefined;
    newParticipantQuestionnaireCount.value = undefined;
    participantFirstConnectionChecked.value = false;
    participantProfile.value = undefined;
    participantFavorites.value = undefined;
    participantPlaylists.value = [];
    participantMemories.value = [];
    isSouvenirOpened.value = false;
    souvenirSelectedPageNumber.value = 1;
};

// Genre
export const allGenresWithAssets = ref<GenreWithAsset[]>([]);

// Period
export const allPeriodsWithAssets = ref<PeriodWithAsset[]>([]);

// Artist
export const allArtists = ref<Artist[]>([]);
export const allArtistData = ref<MusicalListItem[]>([]);

// Album
export const allAlbums = ref<Album[]>([]);
export const allAlbumData = ref<MusicalListItem[]>([]);

// music
export const allMusicData = ref<MusicalListItem[]>([]);
export const musicPlayerData = ref<Track[]>([]);
export const recentSessionLikes = ref<Track[]>([]);
export const recentSessionDislikes = ref<Track[]>([]);
export const recentSessionFavorites = ref<MusicalListItem[]>([]);
export const recentSessionHates = ref<MusicalListItem[]>([]);
export const playingTrackBilan = ref<MusicalListItem>();
export const setMusicPlayerData = (tracks: Track[]) => {
    musicPlayerData.value = tracks;
};
export const resetMusicalSessionData = (skipMusicData: boolean = false) => {
    if (!skipMusicData) musicPlayerData.value = [];
    recentSessionLikes.value = [];
    recentSessionDislikes.value = [];
    recentSessionFavorites.value = [];
    recentSessionHates.value = [];
    playingTrackBilan.value = undefined;
};

export const currentAnimationPlace = ref<AnimationPlace>();

export const currentSessionId = ref<number>();

// session
export const startDate = ref<Date>();
export const endDate = ref<Date>();
export const ongoingAction = ref<SessionAction>();
export const actions = ref<SessionAction[]>([]);

// Drag and Drop
export const draggingElement = ref<HTMLElement | null | undefined>();
export const draggingMusicId = ref<number>();
export const touchMoveStartX = ref<number>();
export const touchMoveStartY = ref<number>();
export const touchMoveEndX = ref<number>();
export const touchMoveEndY = ref<number>();

// Playlist
export const currentPlaylistData = ref<Playlist>();
export const currentPlaylistTracks = ref<MusicalListItem[]>([]);
export const isPlaylistShuffled = ref<boolean>(false);
export const playlistCurrentAudioIndex = ref<number>(0);
export const isPlaylistAudioPlaying = ref<boolean>(false);
export const playlistAudio = new Audio();
export const resetPlaylistData = () => {
    playlistAudio.pause();
    currentPlaylistData.value = undefined;
    currentPlaylistTracks.value = [];
    playlistCurrentAudioIndex.value = 0;
    isPlaylistAudioPlaying.value = false;
    isPlaylistShuffled.value = false;
    musicPlayerData.value = [];
};

// Library
export const selectedLibraryFilters = ref<MusicalListItemType[]>([]);
export const librarySearchText = ref<string>('');
export const currentSelectedLibraryPage = ref<number>(1);
export const resetLibraryData = () => {
    selectedLibraryFilters.value = [];
    librarySearchText.value = '';
    currentSelectedLibraryPage.value = 1;
};

// Research
export const researchText = ref<string>('');
export const researchedData = ref<MusicalListItem[]>([]);
export const researchFilteredData = ref<MusicalListItem[]>([]);
export const researchSelectedFavGenres = ref<GenreWithAsset[]>([]);
export const researchSelectedGenres = ref<GenreWithAsset[]>([]);
export const researchSelectedPeriods = ref<PeriodWithAsset[]>([]);
export const isResearchResultGenerated = ref<boolean>(false);
export const currentSelectedResearchPage = ref<number>(1);
export const resetResearchData = () => {
    researchText.value = '';
    researchedData.value = [];
    researchFilteredData.value = [];
    researchSelectedFavGenres.value = [];
    researchSelectedGenres.value = [];
    researchSelectedPeriods.value = [];
    isResearchResultGenerated.value = false;
    currentSelectedResearchPage.value = 1;
};
