import { UserType } from '@/helper/enums/melovie.enum';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import { TrackFavorite } from '@/helper/interface/favorite.interface';
import { Track } from '@/helper/interface/music/track.interface';
import { TrackDetails, IdTracksPlayList } from '@/helper/interface/playlist.interface';
import { MusicalListItem, ProgressBar } from '@/helper/interface/ui/components/musicalList.interface';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import { musicalListItemIdTextPrefix } from '@/helper/utils/constants';
import { getIconUrl } from '@/helper/utils/genericUtils';
import { getAssets } from '@/services/assetsService';
import { getAllMusicList, addPlaylistTracks, getTrack } from '@/services/music/trackService';
import { removeParticipantFavorite, setParticipantFavorite, setParticipantHate } from '@/services/participantService';
import { deleteTracksFromPlaylist, getPlaylistDetails } from '@/services/playlistService';
import {
    playlistAudio,
    currentUserType,
    staffFavorites,
    participantFavorites,
    currentStaff,
    currentParticipant,
    openAlertPopup,
    isAlertOpened,
    staffHates,
    participantHates,
    currentPlaylistTracks,
    musicPlayerData,
    playlistCurrentAudioIndex,
    isPlaylistAudioPlaying,
    allMusicData,
    setMusicPlayerData,
    isPlaylistShuffled,
    currentPlaylistData,
    isLoading,
    openSouvenirCreator,
} from '@/services/sharedService';
import { removeStaffFavorite, setStaffFavorite, setStaffHate } from '@/services/staffService';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export function usePlaylist(props: any) {
    const { t } = useI18n();
    const router = useRouter();
    const currentAudio = ref<Track>();
    const musicList = ref<MusicalListItem[]>([]); // list of musics which not added in the playlist
    const isPlaying = ref<boolean>(false);
    const isEmptyPlaylist = ref<boolean>(true);

    /**
     * call when clicked on track progress bar
     *
     * @param { PointerEvent } event mouse pointer event to know track position
     */
    const onTrackClick = (event: any) => {
        playlistAudio.pause();
        const progress = event.target.parentElement;
        const maxDuration = playlistAudio.duration;
        const position = event.pageX - progress.offsetLeft;
        let percentage = (100 * position) / progress.offsetWidth;
        percentage > 100 ? (percentage = 100) : percentage < 0 ? (percentage = 0) : null;
        progressBarData.value.progressWidth = percentage + '%';
        playlistAudio.currentTime = (maxDuration * percentage) / 100;
        handlePlayPause();
    };

    // progress bar data for plying music
    const progressBarData = ref<ProgressBar>({
        duration: '--:--',
        currDuration: '00:00',
        progressWidth: '0%',
        onClick: onTrackClick,
    });

    const prevIndex = ref<number>(0); // index of recently played playlistAudio in musical list
    const totalTimeHour = ref<number>(0);
    const totalTimeMin = ref<number>(0);
    const currentHateMusic = ref<MusicalListItem>();
    const selectedTracks = ref<MusicalListItem[]>([]);

    // track-add-modal information
    const isTrackAddModalOpened = ref<boolean>(false);
    const trackAddModalData = ref<ConfirmProps>({
        confirmType: ConfirmType.Alert,
        title: t('views.playlist.addMusics'),
        onClose: () => {
            selectedTracks.value = []; // reset selected tracks on cancel
            isTrackAddModalOpened.value = false;
        },
        left: {
            text: t('generic.cancel'),
            onClick: () => {
                selectedTracks.value = []; // reset selected tracks on cancel
                isTrackAddModalOpened.value = false;
            },
        },
        right: {
            text: t('generic.validate'),
            onClick: async () => {
                await addTracks(); // add selected tracks
                isTrackAddModalOpened.value = false;
            },
        },
    });

    // hate confirm modal information
    const isHateAlertOpened = ref<boolean>(false);
    const hateAlertData = ref<ConfirmProps>({
        confirmType: ConfirmType.Alert,
        title: t('views.playlist.titleToAvoid'),
        headerIcon: 'fa-triangle-exclamation',
        onClose: () => (isHateAlertOpened.value = false),
        // left button
        left: {
            text: t('generic.remove'),
            onClick: () => {
                onRemove(currentHateMusic.value!); // remove hate music from playlist
                isHateAlertOpened.value = false;
            },
        },
        // right button
        right: {
            text: t('generic.keep'),
            onClick: () => (isHateAlertOpened.value = false),
        },
    });

    /**
     * Calculate playlist total time
     *
     */
    const generateTotalTime = () => {
        let min = 0,
            sec = 0;
        currentPlaylistData.value?.tracks.forEach((track: TrackDetails) => {
            if (track.duration) {
                min += parseInt(track.duration.slice(3, 5)); // get minute digit
                sec += parseInt(track.duration.slice(6)); // get second digit
            }
        });
        min += sec / 60; // add second to minute
        totalTimeHour.value = (min / 60) >> 0; // take number without decimal for hour
        totalTimeMin.value = min % 60 >> 0; // take number without decimal for minute
    };

    /**
     * toggle favorite for a music
     *
     * @param {MusicalListItem} track music to make favorite
     */
    const onFavorite = async (track: MusicalListItem) => {
        try {
            isLoading.value = true;
            // load user favorite from cache
            const userFavorite =
                currentUserType.value === UserType.Staff ? staffFavorites.value : participantFavorites.value;

            // check the music is already in favorite list or not
            const alreadyFavorite = userFavorite?.tracks?.find((trk: TrackFavorite) => track.id === trk.id);

            if (alreadyFavorite) {
                // remove from favorite list
                currentUserType.value === UserType.Staff
                    ? await removeStaffFavorite(currentStaff.value?.staf_id!, track.id)
                    : await removeParticipantFavorite(currentParticipant.value?.par_id!, track.id);
            } else {
                // add to favorite list
                currentUserType.value === UserType.Staff
                    ? await setStaffFavorite(currentStaff.value?.staf_id!, [track.id!])
                    : await setParticipantFavorite(currentParticipant.value?.par_id!, [track.id!]);
            }
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isLoading.value = false;
        }
    };

    /**
     * comment for a music
     *
     * @param {MusicalListItem} music music to make comment
     */
    const onComment = (music: MusicalListItem) => openSouvenirCreator(music);

    /**
     * toggle hate for a music
     *
     * @param {MusicalListItem} track music to hate
     */
    const onHate = async (track: MusicalListItem) => {
        try {
            isLoading.value = true;
            // get user hates from cache
            const userHates = currentUserType.value === UserType.Staff ? staffHates.value : participantHates.value;

            // check if the music already in hate list or not
            const alreadyHate = userHates?.tracks?.find((trk: TrackFavorite) => track.id === trk.id);

            if (alreadyHate) {
                // remove from hate list
                currentUserType.value === UserType.Staff
                    ? await removeStaffFavorite(currentStaff.value?.staf_id!, track.id)
                    : await removeParticipantFavorite(currentParticipant.value?.par_id!, track.id);
            } else {
                // add to hate list
                currentUserType.value === UserType.Staff
                    ? await setStaffHate(currentStaff.value?.staf_id!, track.id)
                    : await setParticipantHate(currentParticipant.value?.par_id!, track.id);

                currentHateMusic.value = track; // save track in cache for hate confirm modal
                isHateAlertOpened.value = true; // open hate confirm modal
            }
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isLoading.value = false;
        }
    };

    /**
     * remove music from playlist
     *
     * @param {MusicalListItem} track music to remove
     */
    const onRemove = (track: MusicalListItem) => {
        isLoading.value = true;
        deleteTracksFromPlaylist(currentPlaylistData.value?.play_id!, [track.id!])
            .then((res: IdTracksPlayList[]) => {
                removeMusicHandler(track); // removing the music from cache
                // managing if removed playing music
                if (currentAudio.value?.trk_id !== track.id) {
                    // reset currently playing music's index
                    playlistCurrentAudioIndex.value = musicPlayerData.value.findIndex(
                        (trk: Track) => trk.trk_id === currentAudio.value?.trk_id
                    );
                } else {
                    // plying music removed
                    isPlaying.value = false;
                    isPlaylistAudioPlaying.value = false;
                    playlistAudio.pause();

                    // reinitialize playlist music
                    if (musicPlayerData.value.length) {
                        playlistCurrentAudioIndex.value--;
                        onToggleAudio();
                    }
                }
                generateTotalTime(); // calculate playlist time
            })
            .catch((error) =>
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: error,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                })
            )
            .finally(() => (isLoading.value = false));
    };

    /**
     * handle remove music
     *
     * @param {MusicalListItem} track music to remove
     */
    const removeMusicHandler = (track: MusicalListItem) => {
        currentPlaylistTracks.value.splice(currentPlaylistTracks.value.indexOf(track), 1);
        musicPlayerData.value.splice(
            musicPlayerData.value.findIndex((trk: Track) => trk.trk_id === track.id),
            1
        );
        currentPlaylistData.value?.tracks.splice(
            currentPlaylistData.value.tracks.findIndex((trk: TrackDetails) => trk.id === track.id),
            1
        );
        musicList.value.push(track);
        isEmptyPlaylist.value = musicPlayerData.value.length ? false : true; // check empty playlist
    };

    /**
     * open single music player view
     *
     */
    const onSingleView = () => {
        if (musicPlayerData.value.length) {
            // handling a case, where initially there was no music in the playlist, then added music with add-track-modal.
            if (isEmptyPlaylist.value) handlePlayPause();
            router.push('/playlist/music-player');
        } else
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: t('views.playlist.noMusicAvailable'),
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
    };

    /**
     * open add-track-modal
     *
     */
    const openTrackAddModal = async () => {
        try {
            // load all music data only one time, if music data not available in cache
            if (!allMusicData.value.length) {
                isLoading.value = true;
                allMusicData.value = await getAllMusicList();
                isLoading.value = false;
            }

            // load music list which not added in the playlist
            const presentTrackIds = musicPlayerData.value.map((track: Track) => track.trk_id);
            musicList.value = allMusicData.value.filter(
                (music: MusicalListItem) => music.id && !presentTrackIds.includes(music.id)
            );
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isTrackAddModalOpened.value = true;
            isLoading.value = false;
        }
    };

    /**
     * add tracks to the playlist
     *
     */
    const addTracks = async () => {
        try {
            isLoading.value = true;
            const trackIds: number[] = selectedTracks.value.map((item: MusicalListItem) => item.id!);
            if (trackIds.length) {
                await addPlaylistTracks(parseInt(props.playlistId), trackIds);
                selectedTracks.value = []; // reset add-track-modal data

                // filtering music list which not included in the playlist
                musicList.value = musicList.value.filter(
                    (music: MusicalListItem) => music.id && !trackIds.includes(music.id)
                );
            }
            loadPlaylistData(parseInt(props.playlistId), true); // after adding tracks reload playlist data
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        }
    };

    /**
     * check and if no playlist data available then load data for playlist
     *
     * @param {number} id playlist id
     * @param {boolean} [force=false] set true, to load even previous data available
     */
    const loadPlaylistData = async (id: number, force: boolean = false) => {
        try {
            isLoading.value = true;
            currentPlaylistData.value = await getPlaylistDetails(id);

            // sorting playlist tracks in ascending order by track id
            currentPlaylistData.value.tracks.sort((a: TrackDetails, b: TrackDetails) => a.id - b.id);
            generateTotalTime(); // generate playlist time

            // generate playlist tracks as musical-list-item to show in playlist UI
            currentPlaylistTracks.value = await Promise.all(
                currentPlaylistData.value.tracks.map(async (track: TrackDetails) => {
                    const result: MusicalListItem = {
                        id: track.id,
                        title: track.name,
                        artist: track.artist,
                        logo: getIconUrl('/musics/music-round.png'),
                        type: MusicalListItemType.Track,
                    };
                    // get logo if available
                    if (track.alb_asset) {
                        const logo = await getAssets([track.alb_asset], AssetSourceEnum.Album);
                        if (logo.length) result.logo = logo[0].data;
                    }
                    return result;
                })
            );

            // get track data for music player
            if (!musicPlayerData.value.length || force) {
                const trackIds = currentPlaylistData.value.tracks.map((trackDetail: TrackDetails) => trackDetail.id);
                if (trackIds.length) {
                    const trackList = await getTrack(trackIds);
                    // set global music player data to access in different music listening UI
                    setMusicPlayerData(trackList);
                    sortMusicPlayerData(); // sort tracks in ascending order by id
                }
            }

            if (force) {
                playlistCurrentAudioIndex.value = musicPlayerData.value.findIndex(
                    (trk: Track) => trk.trk_id === currentAudio.value?.trk_id
                );
                initializePlayer(true); // reinitialize player without initializing from scratch

                // if previously shuffled, then shuffle new initialized data
                if (isPlaylistShuffled.value) {
                    isPlaylistShuffled.value = false;
                    shuffleMusics();
                }
            } else isEmptyPlaylist.value = musicPlayerData.value.length ? false : true; // check empty playlist on first time data load
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isLoading.value = false;
        }
    };

    /**
     * handle click on a music
     *
     * @param {MusicalListItem} music the clicked music
     */
    const onRowClick = (music: MusicalListItem) => {
        onToggleAudio(music.id);
    };

    /**
     * Initialize playlist music player data
     *
     * @param {boolean} [skip=false] skip initializing from scratch
     */
    const initializePlayer = async (skip: boolean = false) => {
        currentAudio.value = musicPlayerData.value[playlistCurrentAudioIndex.value] || {};
        if (!skip) {
            playlistAudio.pause(); // stop music if playing to load again
            playlistAudio.src = ''; // make source empty, to reinitialize audio
            progressBarData.value.progressWidth = '0%';
            playlistAudio.currentTime = 0;
            if (currentAudio.value.trk_ass_id) {
                try {
                    isLoading.value = true;
                    const audioRes = await getAssets([currentAudio.value.trk_ass_id], AssetSourceEnum.Track);
                    if (audioRes?.[0]?.data?.length) {
                        playlistAudio.src = audioRes[0].data; // set new audio source
                        markPlayingTrack(); // marking the track in list
                        isPlaylistAudioPlaying.value ? handlePlayPause(true) : playlistAudio.pause();
                    } else throw new Error(t('views.playlist.invalidAudioSource'));
                } catch (error) {
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: `${error}`,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    });
                } finally {
                    isLoading.value = false;
                }
            }
        } else {
            markPlayingTrack();
            isPlaying.value = !playlistAudio.paused;
            playlistAudio.paused ? playlistAudio.pause() : handlePlayPause(true);
        }
    };

    /**
     * Change playing music
     *
     * @param { number } trackId music id to play
     */
    const onToggleAudio = (trackId?: number) => {
        if (trackId) {
            const trackIndex = musicPlayerData.value.findIndex((track: Track) => track.trk_id === trackId);
            if (trackIndex !== -1 && playlistCurrentAudioIndex.value !== trackIndex) {
                // managing state like, playing previous music, so that after toggle it will move to current music
                playlistCurrentAudioIndex.value = trackIndex - 1;
                isPlaylistAudioPlaying.value = true; // to play music by default
            } else return;
        }
        if (playlistCurrentAudioIndex.value < musicPlayerData.value.length - 1) {
            // move to next music if possible
            playlistCurrentAudioIndex.value++;
            initializePlayer();
        } else {
            // other wise move to first music
            playlistAudio.pause;
            isPlaylistAudioPlaying.value = false;
            playlistCurrentAudioIndex.value = 0;
            if (currentPlaylistTracks.value.length) initializePlayer();
        }
    };

    /**
     * Remove track's marking from musical list
     */
    const removeMarking = () => {
        currentPlaylistTracks.value.forEach((music: MusicalListItem) => {
            if (music.progressBar) delete music.progressBar;
        });
    };

    /**
     * Marking playing track to green in musical list
     */
    const markPlayingTrack = () => {
        removeMarking(); // remove marking, if any music have marked before
        const index = currentPlaylistTracks.value.findIndex(
            (music: MusicalListItem) => music.id === currentAudio.value?.trk_id
        );
        if (index >= 0) {
            currentPlaylistTracks.value[index].progressBar = progressBarData.value;
            prevIndex.value = index;

            // scroll to marked music in the list
            const el = document.getElementById(musicalListItemIdTextPrefix + currentAudio.value?.trk_id);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    /**
     * Toggle play pause for a music
     *
     * @param {boolean} [play] set true to play only
     */
    const handlePlayPause = (play?: boolean) => {
        if (currentPlaylistData.value?.tracks?.length) {
            if (isEmptyPlaylist.value) {
                // handling a case, where initially there was no music in the playlist, then added music with add-track-modal.
                isEmptyPlaylist.value = false;
                onToggleAudio();
                return;
            }
            if (playlistAudio.paused || play) {
                playlistAudio
                    .play()
                    .then((res: void) => (isPlaylistAudioPlaying.value = true))
                    .catch((error) =>
                        openAlertPopup({
                            confirmType: ConfirmType.Error,
                            message: error,
                            right: {
                                text: t('generic.back'),
                                onClick: () => {
                                    isAlertOpened.value = false;
                                },
                            },
                        })
                    );
            } else {
                playlistAudio.pause();
                isPlaylistAudioPlaying.value = false;
            }
        }
    };

    /**
     * Generate music duration
     */
    const generateTime = () => {
        const width = (100 / playlistAudio.duration) * playlistAudio.currentTime;
        progressBarData.value.progressWidth = width + '%';

        // calculating current track progress
        const durationMinute = Math.floor(playlistAudio.duration / 60);
        const durationSecond = Math.floor(playlistAudio.duration - durationMinute * 60);
        const currentMinute = Math.floor(playlistAudio.currentTime / 60);
        const currentSecond = Math.floor(playlistAudio.currentTime - currentMinute * 60);

        // generating duration text
        progressBarData.value.duration =
            (!playlistAudio.duration ? '--' : durationMinute < 10 ? '0' + durationMinute : durationMinute) +
            ':' +
            (!playlistAudio.duration ? '--' : durationSecond < 10 ? '0' + durationSecond : durationSecond);
        progressBarData.value.currDuration =
            (currentMinute < 10 ? '0' + currentMinute : currentMinute) +
            ':' +
            (currentSecond < 10 ? '0' + currentSecond : currentSecond);
    };

    /**
     * Shuffle on or off for music list
     */
    const shuffleMusics = () => {
        if (isPlaylistShuffled.value) {
            sortMusicPlayerData();
            playlistCurrentAudioIndex.value = prevIndex.value;
            isPlaylistShuffled.value = false;
        } else {
            sortMusicPlayerData(true);
            playlistCurrentAudioIndex.value = 0;
            isPlaylistShuffled.value = true;
        }
    };

    /**
     * Sort music player data
     *
     * @param {boolean} [random=false] randomize or not
     */
    const sortMusicPlayerData = (random: boolean = false) => {
        musicPlayerData.value.sort((curr: Track, next: Track) => {
            let result = 0;
            if (random)
                result =
                    curr.trk_id === currentAudio.value?.trk_id // set current playing music to top
                        ? -1
                        : next.trk_id === currentAudio.value?.trk_id
                        ? 1
                        : 0.5 - Math.random();
            else result = curr.trk_id - next.trk_id; // sorting by track id in ascending order
            return result;
        });
    };

    return {
        musicList,
        isPlaying,
        prevIndex,
        currentAudio,
        totalTimeMin,
        totalTimeHour,
        hateAlertData,
        selectedTracks,
        progressBarData,
        isEmptyPlaylist,
        currentHateMusic,
        trackAddModalData,
        isHateAlertOpened,
        isTrackAddModalOpened,
        onHate,
        onRemove,
        onComment,
        addTracks,
        onRowClick,
        onFavorite,
        generateTime,
        onTrackClick,
        onSingleView,
        shuffleMusics,
        onToggleAudio,
        removeMarking,
        handlePlayPause,
        initializePlayer,
        markPlayingTrack,
        loadPlaylistData,
        generateTotalTime,
        openTrackAddModal,
    };
}
