
import Header from '@/components/header/HeaderComponent.vue';
import { defineComponent, onBeforeMount, onUnmounted, ref, watch } from 'vue';
import MusicalList from '@/components/musical_list/MusicalListComponent.vue';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { ActionLabel, ActionOrigin, ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import {
    currentParticipant,
    isAlertOpened,
    isLoading,
    draggingMusicId,
    openAlertPopup,
    participantFavorites,
    recentSessionDislikes,
    recentSessionFavorites,
    recentSessionHates,
    recentSessionLikes,
    resetMusicalSessionData,
    touchMoveStartX,
    touchMoveStartY,
    touchMoveEndX,
    touchMoveEndY,
    draggingElement,
    playingTrackBilan,
    isSouvenirCreatorOpened,
    openPlaylistCreator,
    openSouvenirCreator,
    currentSessionId,
} from '@/services/sharedService';
import { useRouter } from 'vue-router';
import { Track } from '@/helper/interface/music/track.interface';
import { getIconUrl } from '@/helper/utils/genericUtils';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { getAssets } from '@/services/assetsService';
import {
    setParticipantDislike,
    setParticipantFavorite,
    setParticipantHate,
    setParticipantLike,
} from '@/services/participantService';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useI18n } from 'vue-i18n';
import { Asset } from '@/helper/interface/ui/ui.interface';
import { TrackFavorite } from '@/helper/interface/favorite.interface';
import { ActionData } from '@/helper/interface/session.interface';
import { addAction } from '@/services/sessionService';
import SouvenirCreatorComponent from '@/components/souvenir_creator/SouvenirCreatorComponent.vue';

export default defineComponent({
    name: 'MusicReport',
    components: {
        Header,
        MusicalList,
        SouvenirCreatorComponent,
    },
    setup() {
        isLoading.value = true;
        const { t } = useI18n();
        const router = useRouter();
        const audio = new Audio();
        const currentTrack = ref<MusicalListItem>();
        const likedSongLists = ref<MusicalListItem[]>([]);
        const dislikedSongLists = ref<MusicalListItem[]>([]);

        const onDropDisliked = (event: any) => {
            const trackId: string = event.dataTransfer.getData('item_id');
            if (trackId.length > 0) handleDragDrop(parseInt(trackId), -1);
        };

        const onDropLiked = (event: any) => {
            const trackId: string = event.dataTransfer.getData('item_id');
            if (trackId.length > 0) handleDragDrop(parseInt(trackId), 1);
        };

        const handleTouchEnd = () => {
            if (
                touchMoveStartX.value &&
                touchMoveStartY.value &&
                touchMoveEndX.value &&
                touchMoveEndY.value &&
                draggingMusicId.value
            ) {
                const dragFromLikeElement = document
                    .elementFromPoint(touchMoveStartX.value, touchMoveStartY.value)
                    ?.closest('.liked-songs');

                const dragFromDislikeElement = document
                    .elementFromPoint(touchMoveStartX.value, touchMoveStartY.value)
                    ?.closest('.disliked-songs');

                const dropToLikeElement = document
                    .elementFromPoint(touchMoveEndX.value, touchMoveEndY.value)
                    ?.closest('.liked-songs');

                const dropToDislikeElement = document
                    .elementFromPoint(touchMoveEndX.value, touchMoveEndY.value)
                    ?.closest('.disliked-songs');

                if (
                    dragFromLikeElement &&
                    dropToDislikeElement &&
                    !dragFromLikeElement.isSameNode(dropToDislikeElement)
                )
                    handleDragDrop(draggingMusicId.value, -1); // drop on dislike
                else if (
                    dragFromDislikeElement &&
                    dropToLikeElement &&
                    !dragFromDislikeElement.isSameNode(dropToLikeElement)
                )
                    handleDragDrop(draggingMusicId.value, 1); // drop on like
            }

            draggingMusicId.value = undefined;
            touchMoveStartX.value = undefined;
            touchMoveStartY.value = undefined;
            touchMoveEndX.value = undefined;
            touchMoveEndY.value = undefined;
            if (draggingElement.value) {
                draggingElement.value.style.position = 'initial';
                draggingElement.value.style.width = '100%';
            }
            draggingElement.value = undefined;
        };

        const handleDragDrop = (trackId: number, type: number) => {
            audio.pause();
            switch (type) {
                case 1:
                    const targetLikedMusic = dislikedSongLists.value.find(
                        (item: MusicalListItem) => item.id === trackId
                    );
                    if (targetLikedMusic) {
                        if (recentSessionHates.value.includes(targetLikedMusic))
                            recentSessionHates.value.splice(recentSessionHates.value.indexOf(targetLikedMusic), 1);
                        likedSongLists.value.push(
                            ...dislikedSongLists.value.splice(dislikedSongLists.value.indexOf(targetLikedMusic), 1)
                        );
                    }
                    const targetLikedTrackIndex = recentSessionDislikes.value.findIndex(
                        (item: Track) => item.trk_id === trackId
                    );
                    if (targetLikedTrackIndex > -1)
                        recentSessionLikes.value.push(...recentSessionDislikes.value.splice(targetLikedTrackIndex, 1));
                    break;

                case -1:
                    const targetDislikedMusic = likedSongLists.value.find(
                        (item: MusicalListItem) => item.id === trackId
                    );
                    if (targetDislikedMusic)
                        dislikedSongLists.value.push(
                            ...likedSongLists.value.splice(likedSongLists.value.indexOf(targetDislikedMusic), 1)
                        );

                    const targetDislikedTrackIndex = recentSessionLikes.value.findIndex(
                        (item: Track) => item.trk_id === trackId
                    );
                    if (targetDislikedTrackIndex > -1)
                        recentSessionDislikes.value.push(
                            ...recentSessionLikes.value.splice(targetDislikedTrackIndex, 1)
                        );
                    break;
            }
            draggingMusicId.value = undefined;
        };

        const allowDrop = (event: any) => {
            event.preventDefault();
        };

        const onPlay = (row: MusicalListItem, like: boolean = true): MusicalListItem | undefined => {
            const actionData: ActionData = {
                trk: row.id!,
                origin: ActionOrigin.Bilan,
            };
            if (!currentTrack.value || currentTrack.value?.id !== row.id) {
                if (currentTrack.value?.id)
                    addAction(ActionLabel.EndPlay, {
                        trk: currentTrack.value.id,
                        origin: ActionOrigin.Bilan,
                    });
                if (audio.src?.length) audio.pause();
                let track: Track | undefined;
                track = (like ? recentSessionLikes.value : recentSessionDislikes.value).find(
                    (item: Track) => item.trk_id === row.id
                );
                if (track?.trk_ass_id) {
                    isLoading.value = true;
                    getAssets([track.trk_ass_id], AssetSourceEnum.Track)
                        .then((res: Asset[]) => {
                            audio.src = res?.[0]?.data;
                            addAction(ActionLabel.Select, actionData);
                            audio.play();
                            addAction(ActionLabel.Play, actionData);
                            currentTrack.value = row;
                        })
                        .catch((error) =>
                            openAlertPopup({
                                confirmType: ConfirmType.Error,
                                message: error,
                                right: {
                                    text: t('generic.back'),
                                    onClick: () => {
                                        isAlertOpened.value = false;
                                    },
                                },
                            })
                        )
                        .finally(() => (isLoading.value = false));
                }
            } else if (audio.src?.length) {
                if (audio.paused) {
                    audio.play();
                    addAction(ActionLabel.Resume, actionData);
                } else {
                    audio.pause();
                    addAction(ActionLabel.Pause, actionData);
                    return undefined;
                }
            }
            return row;
        };

        const onFavorite = (track: MusicalListItem) => {
            if (recentSessionHates.value.includes(track))
                recentSessionHates.value.splice(recentSessionHates.value.indexOf(track), 1);
            recentSessionFavorites.value.includes(track)
                ? recentSessionFavorites.value.splice(recentSessionFavorites.value.indexOf(track), 1)
                : recentSessionFavorites.value.push(track);
        };

        const onHate = (track: MusicalListItem) => {
            if (recentSessionFavorites.value.includes(track))
                recentSessionFavorites.value.splice(recentSessionFavorites.value.indexOf(track), 1);
            recentSessionHates.value.includes(track)
                ? recentSessionHates.value.splice(recentSessionHates.value.indexOf(track), 1)
                : recentSessionHates.value.push(track);
        };

        const onExit = async () => {
            try {
                isLoading.value = true;
                if (likedSongLists.value.length) {
                    await Promise.all(
                        likedSongLists.value.map(
                            async (track: MusicalListItem) =>
                                await setParticipantLike(currentParticipant.value?.par_id!, track.id)
                        )
                    );
                }
                if (dislikedSongLists.value.length) {
                    await Promise.all(
                        dislikedSongLists.value.map(
                            async (track: MusicalListItem) =>
                                await setParticipantDislike(currentParticipant.value?.par_id!, track.id)
                        )
                    );
                }
                if (recentSessionHates.value.length) {
                    await Promise.all(
                        recentSessionHates.value.map(
                            async (track: MusicalListItem) =>
                                await setParticipantHate(currentParticipant.value?.par_id!, track.id)
                        )
                    );
                }
                if (recentSessionFavorites.value.length) {
                    await setParticipantFavorite(
                        currentParticipant.value?.par_id!,
                        recentSessionFavorites.value.map((track: MusicalListItem) => track.id!)
                    );
                }
                openAlertPopup({
                    confirmType: ConfirmType.Success,
                    message: t('views.participantDashboard.discovery.musicalSessionDataSaved'),
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
                if (currentTrack.value?.id)
                    addAction(ActionLabel.EndPlay, {
                        trk: currentTrack.value.id,
                        origin: ActionOrigin.Bilan,
                    });
                resetMusicalSessionData();
                // Go to report interface if needed ! (if we have an sessionID value)
                if (currentSessionId.value !== undefined) {
                    router.push(`/report/${currentSessionId.value}`);
                } else {
                    router.push('/participant/discovery');
                }
            } catch (error) {
                isLoading.value = false;
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: `${error}`,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
            } finally {
                isLoading.value = false;
            }
        };

        const onAddToPlaylist = () => {
            openPlaylistCreator(likedSongLists.value);
        };

        watch(
            () => playingTrackBilan.value,
            (newValue: MusicalListItem | undefined) => {
                if (!newValue) audio.pause();
            }
        );

        onBeforeMount(async () => {
            audio.onended = () => {
                audio.pause();
                playingTrackBilan.value = undefined;
            };
            if (recentSessionLikes.value.length || recentSessionDislikes.value.length) {
                likedSongLists.value = await Promise.all(
                    recentSessionLikes.value.map(async (track: Track) => {
                        let like: MusicalListItem = {
                            id: track.trk_id,
                            title: track.trk_name || '',
                            description: track.trk_description || '',
                            artist: track.art_name,
                            type: MusicalListItemType.Track,
                            logo: getIconUrl('/musics/music-round.png'),
                        };
                        if (track.alb_ass_id) {
                            let logo = await getAssets([track.alb_ass_id], AssetSourceEnum.Album);
                            if (logo.length) like.logo = logo[0].data;
                        }

                        if (participantFavorites.value?.tracks?.find((fav: TrackFavorite) => fav.id === like.id))
                            recentSessionFavorites.value.push(like);

                        return like;
                    })
                );
                dislikedSongLists.value = await Promise.all(
                    recentSessionDislikes.value.map(async (track: Track) => {
                        let disLike: MusicalListItem = {
                            id: track.trk_id,
                            title: track.trk_name || '',
                            description: track.trk_description || '',
                            artist: track.art_name,
                            type: MusicalListItemType.Track,
                            logo: getIconUrl('/musics/music-round.png'),
                        };

                        if (track.alb_ass_id) {
                            let logo = await getAssets([track.alb_ass_id], AssetSourceEnum.Album);
                            if (logo.length) disLike.logo = logo[0].data;
                        }

                        if (participantFavorites.value?.tracks?.find((fav: TrackFavorite) => fav.id === disLike.id))
                            recentSessionFavorites.value.push(disLike);

                        return disLike;
                    })
                );
                isLoading.value = false;
            } else {
                resetMusicalSessionData();
                router.push('/participant/discovery');
                isLoading.value = false;
            }
        });

        onUnmounted(() => {
            audio.pause();
            playingTrackBilan.value = undefined;
            if (
                recentSessionFavorites.value.length ||
                recentSessionHates.value.length ||
                recentSessionLikes.value.length ||
                recentSessionDislikes.value.length
            ) {
                confirm(t('views.participantDashboard.discovery.musicalSessionDataSaveAlert'))
                    ? onExit()
                    : resetMusicalSessionData();
            }
        });

        return {
            likedSongLists,
            dislikedSongLists,
            onPlay,
            onHate,
            onExit,
            allowDrop,
            onFavorite,
            onDropLiked,
            onDropDisliked,
            handleTouchEnd,
            isSouvenirCreatorOpened,
            onAddToPlaylist,
            openSouvenirCreator,
        };
    },
});
